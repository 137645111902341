html {
    scroll-behavior: smooth;
}

.body {
    display: flex;
    justify-content: space-between;
    margin-left: 32px;
    margin-right: 32px;
}

.left-column {
    width: 12.5%;
    height: calc(100vh - 80px);
    display: flex;
    align-items: center;
    position: fixed;
    top: 80px;
    left: 32px;
}

.shortcut-menu {
    display: flex;
    list-style-type: none;
    flex-direction: column;
    align-items: baseline;
    justify-content: flex-start;
    height: 50vh;
    margin: 0;
    padding: 0;
    transition: opacity 0.4s ease-in-out, transform 0.4s ease-in-out;
}

.shortcut-menu.show {
    opacity: 1;
    transform: translateY(-108px);
}

.shortcut-menu.hide {
    opacity: 0;
    pointer-events: none; 
}

.left-column-list-text {
    margin-bottom: 8px;
}

.left-column-list-text a {
    color: #929292;
    text-decoration: none;
}

.left-column-list-text a:hover {
    color: var(--text-color); 
}

.left-column-list-text.active {
    font-weight: 400;
}
  
.left-column-list-text.active a {
    color: var(--text-color);
}

.theme-toggle {
    position: absolute;
    bottom: 0;
    height: 120px;
    display: flex;
    align-items: center;
    cursor: pointer;
    z-index: 120;
}

.theme-toggle span {
    font-size: 12px;
}

.main-content {
    width: calc(100% - 12.5% - 16px);
    margin-left: calc(12.5% + 32px);
}

.header-img {
    margin-top: 36px;
    width: 100%;
    position: relative;
    padding-top: 56.25%;
    overflow: hidden; 
}

.header-img img {
    width: 100%;
    height: auto;
    object-fit: cover;
    position: absolute;
    top: 0;
    left: 0; 
}

.project-tag-list {
    margin-top: 48px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.project-tag {
    width: 19%;
}

#button-wrapper {
    display: flex;
    flex-direction: column;
}

.button-text {
    padding-top: 8px;
    padding-bottom: 8px;
    padding-left: 12px;
    padding-right: 12px;
    border: 1px solid #929292;
    background-color: var(--background-color);
    border-radius: 8px;
    margin-bottom: 8px;
    text-align: left;
}

.button-text:hover {
    background-color: var(--hover-color);
    border: 1px solid var(--background-color);
}

/*Content*/

.content-section {
    padding-top: 60px;
    padding-bottom: 60px;
}

#context {
    margin-top: 60px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
}

#context-content {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

#context-text {
    line-height: 160%;
    font-weight: 200;
}

/*
#motivation {
    margin-top: 60px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
}

#motivation-content {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

#motivation-text {
    width: 59.5%;
}

#motivation-img {
    width: 39.25%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

#cost-and-benefit {
    margin-top: 30px;
    margin-bottom: -10px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 240px;
}

#cost {
    text-align: left;
}

#benefit {
    text-align: right;
}

#line {
    text-align: center;
    font-weight: 100;
}

#triangle {
    font-size: 32px;
    font-weight: 100;
    margin-top: -12px;
}
*/

#background {
    margin-top: 1260px;
}

.impact-box-wrapper {
    margin-top: 28px;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    width: 100%;
    gap: 12px;
}

.impact-box-item {
    width: 27%;
    display: flex;
    padding: 32px 28px 20px 28px;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 0px;
    background: rgba(var(--text-color-rgb), 0.05);
    line-height: 160%;
    font-size: 15px;
    font-weight: 200;
}

.impact-box-icon {
    font-size: 36px;
    height: 36px;
}

#plane-icon {
    font-size: 28px;
    height: 36px;
}

#mail-icon {
    margin-top: -4px;
}

.survey-img-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
}

.survey-img-list {
    display: flex;
    justify-content: space-between;
    width: 80%;
    margin-top: 40px;
    margin-bottom: 40px;
}

#survey-img1 {
    width: 47%;
}

#survey-img2 {
    width: 47%;
}

#storyboard-img {
    width: 100%;
}

#sketch-img {
    margin-top: 20px;
    width: 100%;
}

#solution-img1 {
    margin-top: 40px;
    width: 100%;
}

.solution-img-wrapper {
    margin-top: 40px;
    display: flex;
    justify-content: space-between;
    width: 100%;
}

#solution-img2-wrapper {
    width: 49%;
}

#solution-video1 {
    width: 100%;
}

#solution-img3-wrapper {
    width: 49%;
}

#solution-video2 {
    width: 100%;
}

.image-subtext {
    margin-top: 8px;
    font-size: 14px;
    font-weight: 400;
}



/* Footer */

.project-footer {
    z-index: 100;
    margin-top: 120px;
    padding-left: 32px;
    padding-right: 32px;
    z-index: 20;
    position: relative;
    background-color: var(--background-color);
}

.project-footer-top {
    display: flex;
    justify-content: space-between;
    gap: 40px;
}

.project-footer-previous {
    display: flex;
    width: 420px;
    padding: 24px 0px;
    flex-direction: column;
    align-items: flex-start;
}

.project-footer-next {
    display: flex;
    width: 420px;
    padding: 24px 0px;
    flex-direction: column;
    align-items: flex-end;
}

.project-footer-subtitle {
    font-size: 14px;
    color: #929292;
    margin-bottom: 8px;
    overflow: hidden;
    white-space: nowrap; 
    text-overflow: ellipsis; 
}

.project-footer-title {
    font-size: 24px;
    line-height: 140%;
    margin-top: 0;
    margin-bottom: 28px;
    height: 72px;
    overflow: hidden;
}

.project-footer-next .project-footer-title {
    text-align: right;
}

.read-more-button {
    padding-top: 8px;
    padding-bottom: 8px;
    padding-left: 12px;
    padding-right: 12px;
    font-size: 14px;
    color: #929292;
    border: 1px solid #929292;
    background-color: var(--background-color);
    border-radius: 8px;
    margin-bottom: 8px;
    text-align: left;
}

.read-more-button:hover {
    background-color: var(--hover-color);
    border: 1px solid var(--background-color);
}

.divider {
    width: 100%;
    height: 1px;
    background-color: #929292;
}



/* Responsive design */
@media (min-width: 1440px) {
    .body {
        margin-left: calc((100% - 1440px) / 2 + 32px) !important;
        margin-right: calc((100% - 1440px) / 2 + 32px) !important;
    }
    .left-column {
        left: calc((100% - 1440px) / 2 + 32px) !important;
    }
    .project-footer {
        padding-left: calc((100% - 1440px) / 2 + 32px) !important;
        padding-right: calc((100% - 1440px) / 2 + 32px) !important;
    }
}
@media (max-width: 768px) {
    .left-column {
        display: none;
    }
    .main-content {
        width: 100%;
        margin-left: 0px;
    }
    .project-tag-list {
        flex-direction: column !important;
    }
    .project-tag-row-1, .project-tag-row-2 {
        width: 100%;
    }
    .project-tag-row-3 {
        width: 100%;
        flex-direction: row !important;
        margin-top: 20px;
    }
    .button-text {
        margin-right: 12px;
    }
    .survey-img-list {
        flex-direction: column;
        width: 60%;
    }
    #survey-img1 {
        width: 100%;
        margin-bottom: 32px;
    }
    #survey-img2 {
        width: 100%;
    }
    .project-footer-top {
        flex-direction: column;
    }
    .project-footer-previous, .project-footer-next {
        width: 100%;
    }
}
@media (max-width: 600px) {
    .body {
        margin-left: 24px;
        margin-right: 24px;
    }
    .survey-img-list {
        flex-direction: column;
        width: 80%;
    }
    .solution-img-wrapper {
        flex-direction: column;
    }
    #solution-img2-wrapper, #solution-img3-wrapper {
        width: 100%;
    }
    .project-footer {
        padding-left: 24px;
        padding-right: 24px;
    }
}