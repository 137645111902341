.body {
    display: flex;
    justify-content: space-between;
    margin-left: 32px;
    margin-right: 32px;
}

.left-column {
    width: 16.6%;
    height: calc(100vh - 80px);
    display: flex;
    align-items: center;
    position: fixed;
    top: 80px;
    left: 32px;
}

.shortcut-menu {
    display: flex;
    list-style-type: none;
    flex-direction: column;
    align-items: baseline;
    justify-content: flex-start;
    height: 50vh;
    margin: 0;
    padding: 0;
    transition: opacity 0.4s ease-in-out, transform 0.4s ease-in-out;
}

.shortcut-menu.show {
    opacity: 1;
    transform: translateY(-108px);
}

.shortcut-menu.hide {
    opacity: 0;
    pointer-events: none; 
}

.left-column-list-text {
    margin-bottom: 8px;
}

.left-column-list-text a {
    color: #929292;
    text-decoration: none;
}

.left-column-list-text a:hover {
    color: var(--text-color); 
}

.left-column-list-text.active {
    font-weight: 400;
}
  
.left-column-list-text.active a {
    color: var(--text-color);
}

.theme-toggle {
    position: absolute;
    bottom: 0;
    height: 120px;
    display: flex;
    align-items: center;
    cursor: pointer;
    z-index: 120;
}

.theme-toggle span {
    font-size: 12px;
}

.main-content {
    width: calc(100% - 16.6% - 16px);
    margin-left: calc(16.6% + 32px);
    
}

.header-img {
    margin-top: 36px;
    width: 100%;
    position: relative;
    padding-top: 56.25%;
    overflow: hidden; 
}

.header-img img {
    width: 100%;
    height: auto;
    object-fit: cover;
    position: absolute;
    top: 0;
    left: 0; 
}

.project-tag-list {
    margin-top: 48px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: 12px;
}

.project-tag-row-1, .project-tag-row-2 {
    width: 50%;
    gap: 12px;
    display: flex;
    justify-content: space-between;
}

.project-tag-row-3 {
    width: 19%;
    display: flex;
    flex-direction: column;
}

.project-tag-item {
    width: 50%;
}


/*Content*/


#problem {
    margin-top: 1260px;
    width: 60%;
}

.content-section {
    padding-top: 60px;
    padding-bottom: 60px;
    margin-top: 60px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
}

.content-tag {
    font-size: 12px;
    color: #848484;
    margin-bottom: 16px;
}

#problem-statement {
    margin-top: 8px;
}

#research-question {
    margin-top: 8px;
}

.method-box-wrapper {
    display: flex;
    gap: 12px;
    margin-top: -20px;
}

.method-box {
    box-sizing: border-box;
    width: 50%;
    padding-top: 4px;
    padding-bottom: 4px;
    padding-left: 24px;
    padding-right: 24px;
    background-color: #232323;
    border-radius: 4px;
}

.behavioral-mapping-video-wrapper {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-bottom: 100px;
}

#behavioral-mapping-video {
    width: 67%;
}

.finding-section {
    margin-top: 60px;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 20px;
}

#finding-section2 {
    display: flex;
    align-items: flex-start;
}

#finding-section3 {
    display: flex;
    align-items: flex-end;
    text-align: right;
}

#finding-tag {
    width: 67%;
}

#finding-statement {
    width: 67%;
    margin-top: 0px;
}

#finding-text {
    margin-top: 4px;
    width: 67%;
}

#finding2-img {
    width: 33%;
    padding-left: 24px;
}

#finding3-img {
    width: 33%;
    padding-right: 24px;
}


#similarity-wrapper {
    display: flex;
    gap: 24px;
    margin-top: 40px;
    margin-bottom: 40px;
}

#similarity-item {
    width: 33%;
}

#similarity-title {
    font-weight: 600;
    line-height: 180%;
    font-size: 14px;
    margin-bottom: -4px;
}

.similarity-img {
    width: 100%;
    border-radius: 4px;
    margin-bottom: 8px;
}

.similarity-text {
    line-height: 160%;
    font-weight: 400;
    font-size: 13px;
}

#blue-dot-text {
    font-weight: 600;
    color: #2471C9;
}

.vizimg {
    position: relative;
    background-size: cover;
    background-position: center;
    width: 100%;
    background-image: url("./assets/img/Subway/viz-img.png");
    overflow: hidden;
    margin-top: 28px;
}
  
.viz {
    height: 640px;
}
  
.info1-wrapper {
    position: absolute;
    top: 10px;
    right: calc(50% - 100px);
}
  
.info1 {
    display: block;
    margin-top: 60px;
}
  
.circle1 {
    height: 32px;
}
  
.circle1-wrapper {
    position: relative;
}
  
.popup {
    margin-top: 8px;
    position: absolute;
    width: 360px;
    right: -136px;
    display: none;
    border-radius: 8px;
}
  
.popup-img {
    width: 360px;
    border-radius: 10px;
    -webkit-box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.1), 0 3px 10px 0 rgba(0, 0, 0, 0.1);
            box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.1), 0 3px 10px 0 rgba(0, 0, 0, 0.1);
}
  
.circle1-wrapper:hover .popup {
    display: block;
}
  
.info2-wrapper {
    position: absolute;
    bottom: 100px;
    left: calc(50% - 240px);
}
  
.info2 {
    display: block;
    margin-top: 60px;
}
  
.circle2 {
    height: 32px;
}
  
.circle2-wrapper {
    height: 398px;
    width: 32px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-box-align: end;
        -ms-flex-align: end;
            align-items: flex-end;
}
  
.popup2 {
    margin-bottom: 52px;
    position: absolute;
    height: 364px;
    right: -146px;
    display: none;
    border-radius: 8px;
}
  
.popup2-img {
    height: 364px;
    -webkit-box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.1), 0 3px 10px 0 rgba(0, 0, 0, 0.1);
            box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.1), 0 3px 10px 0 rgba(0, 0, 0, 0.1);
}
  
.circle2-wrapper:hover .popup2 {
    display: block;
}

.user-test-setup {
    width: 100%;
    margin-top: 20px;
    margin-bottom: 20px;
}

#test-setup-subtext {
    margin-top: 40px;
}




/* Footer */

.project-footer {
    z-index: 100;
    margin-top: 120px;
    padding-left: 32px;
    padding-right: 32px;
    z-index: 20;
    position: relative;
    background-color: var(--background-color);
}

.project-footer-top {
    display: flex;
    justify-content: space-between;
    gap: 40px;
}

.project-footer-previous {
    display: flex;
    width: 420px;
    padding: 24px 0px;
    flex-direction: column;
    align-items: flex-start;
}

.project-footer-next {
    display: flex;
    width: 420px;
    padding: 24px 0px;
    flex-direction: column;
    align-items: flex-end;
}

.project-footer-subtitle {
    font-size: 14px;
    color: #929292;
    margin-bottom: 8px;
    overflow: hidden;
    white-space: nowrap; 
    text-overflow: ellipsis; 
}

.project-footer-title {
    font-size: 24px;
    line-height: 140%;
    margin-top: 0;
    margin-bottom: 28px;
    height: 72px;
    overflow: hidden;
}

.project-footer-next .project-footer-title {
    text-align: right;
}

.read-more-button {
    padding-top: 8px;
    padding-bottom: 8px;
    padding-left: 12px;
    padding-right: 12px;
    font-size: 14px;
    color: #929292;
    border: 1px solid #929292;
    background-color: var(--background-color);
    border-radius: 8px;
    margin-bottom: 8px;
    text-align: left;
}

.read-more-button:hover {
    background-color: var(--hover-color);
    border: 1px solid var(--background-color);
}

.divider {
    width: 100%;
    height: 1px;
    background-color: #929292;
}



/* Responsive design */
@media (min-width: 1440px) {
    .body {
        margin-left: calc((100% - 1440px) / 2 + 32px) !important;
        margin-right: calc((100% - 1440px) / 2 + 32px) !important;
    }
    .left-column {
        left: calc((100% - 1440px) / 2 + 32px) !important;
    }
    .project-footer {
        padding-left: calc((100% - 1440px) / 2 + 32px) !important;
        padding-right: calc((100% - 1440px) / 2 + 32px) !important;
    }
}
@media (max-width: 768px) {
    .left-column {
        display: none;
    }
    .main-content {
        width: 100%;
        margin-left: 0px;
    }
    .project-tag-list {
        flex-direction: column !important;
    }
    .project-tag-row-1, .project-tag-row-2 {
        width: 100%;
    }
    .project-tag-row-3 {
        width: 100%;
        flex-direction: row !important;
        margin-top: 20px;
    }
    .button {
        margin-right: 12px;
    }
    .project-footer-top {
        flex-direction: column;
    }
    .project-footer-previous, .project-footer-next {
        width: 100%;
    }
}
@media (max-width: 600px) {
    .body {
        margin-left: 24px;
        margin-right: 24px;
    }
    .solution-img-wrapper {
        flex-direction: column;
    }
    #solution-img2-wrapper, #solution-img3-wrapper {
        width: 100%;
    }
    .project-footer {
        padding-left: 24px;
        padding-right: 24px;
    }
    .method-box-wrapper {
        flex-direction: column;
        gap: 0px;
    }
    .method-box {
        width: 100%;
    }
    .box-text {
        text-align: left;
    }
    #behavioral-mapping-video {
        width: 100%;
    }
    #finding-tag {
        width: 100%;
    }
    #finding-statement {
        width: 100%;
    }
    #finding-text {
        width: 100%;
    }
    #similarity-wrapper {
        flex-direction: column;
    }
    #similarity-item {
        width: 100%;
    }
}