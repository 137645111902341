footer {
    padding-left: 32px;
    padding-right: 32px;
    display: flex;
    align-items: center;
    height: 120px;
    font-size: 12px;
    font-weight: 300;
    display: flex;
    justify-content: space-between;
}

.right-section {
    display: flex;
}

.footer-link {
    text-decoration: underline;
    text-underline-offset: 2.5px;
    color: var(--text-color);
    margin-left: 32px;
}

.footer-link:visited {
    text-decoration: none;
}


/*Responsive Design*/
@media (min-width: 1440px) {
    footer {
        padding-left: calc((100% - 1440px) / 2 + 32px);
        padding-right: calc((100% - 1440px) / 2 + 32px);
    }
}
@media (max-width: 600px) {
    footer {
        padding-left: 24px;
        padding-right: 24px;
    }
}