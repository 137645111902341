html {
    scroll-behavior: smooth;
    margin: 0;
}

#about-img-subtext {
    margin-top: 8px;
    font-size: 14px;
    font-weight: 400;
    color: #929292;
    font-size: 12px;
    line-height: 150%;
}

#about-body {
    padding-left: 32px;
    padding-right: 32px;
    width: 100%;
    box-sizing: border-box;
}

.about-section1 {
    margin-top: 108px;
    margin-bottom: 108px;
    display: flex;
    justify-content: space-between;
    gap: 12px;
}

.about-section1-left {
    width: 75%;
    font-size: 36px;
    font-weight: 200;
    margin-top: 0;
    padding-right: 20px;
}

.about-section1-right {
    width: 25%;
}

.about-section2 {
    margin-top: 36px;
    margin-bottom: 80px;
    display: flex;
    justify-content: space-between;
    gap: 12px;
}

.about-section2-left {
    width: 25%;
    font-size: 14px;
    font-weight: 300;
}

.about-section2-right {
    width: 75%;
    font-size: 14px;
    font-weight: 300;
    line-height: 160%;
}

.about-section2-right p {
    margin-top: 0;
}

.about-img {
    width: 100%;
}

#about-link {
    position: relative;
    color: var(--link-color);
    text-decoration: none;
}

#about-link::before {
    content: '';
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 1px;
    background-color: var(--link-color);
}

#about-link:hover::before {
    opacity: 0;
}

#about-link::after {
    content: '';
    position: absolute;
    left: 0;
    bottom: 0;
    width: 0;
    height: 1px;
    background-color: var(--link-color);
    transition: width 0.7s ease;
}  

#about-link:hover::after {
    width: 100%;
}  

#about-theme-toggle {
    position: fixed;
    bottom: 0;
    height: 120px;
    display: flex;
    align-items: center;
    cursor: pointer;
}

#about-theme-toggle span {
    font-size: 12px;
}



/* Responsive design */
@media (max-width: 768px) {
    .about-section1-left {
        font-size: 24px;
    }
}
@media (max-width: 600px) {
    #about-body {
        padding-left: 24px;
        padding-right: 24px;
    }
    #about-theme-toggle {
        display: none;
    }
    #about-body {
        flex-direction: column;
    }
    #about-left-column {
        width: 100%;
    }
    #about-main-content {
        width: 100%;
        margin-left: 0px;
    }
    .about-section1 {
        margin-top: 98px;
        flex-direction: column;
    }
    .about-section1-left {
        width: 100%;
        line-height: 125%;
    }
    .about-section1-right {
        width: 100%;
    }
    .about-section2 {
        flex-direction: column;
    }
    .about-section2-left {
        width: 100%;
        margin-bottom: 8px;
    }
    .about-section2-right {
        width: 100%;
    }
}