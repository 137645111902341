.main {
  margin-left: 32px;
  margin-right: 32px;
}


/* Hero */

.hero {
  margin-top: 80px;
  width: 100%;
  box-sizing: border-box;
}

.hero-section1 {
  margin-top: 240px;
  margin-bottom: 20px;
  font-size: 80px;
}

.my-name {
  font-size: 80px;
  font-weight: 400;
  line-height: 140%;
  margin-bottom: 0px;
  z-index: 200;
}

.hero-section2 {
  margin-top: 48px;
  margin-bottom: 80px;
  display: flex;
  justify-content: space-between;
  gap: 12px;
}

.hero-section2-left-column {
  width: 25%;
}

.hero-section2-right-column {
  width: 75%;
  padding-left: 12px;
  display: flex;
  flex-direction: column;
  align-items: space-between;
  justify-content: space-between;
}

.my-description {
  font-size: 36px;
  font-weight: 200;
  margin-top: 0;
}

.scroll {
  font-size: 14px;
  font-weight: 200;
  padding-bottom: 72px;
}

#hero-img {
  width: 100%;
  border-radius: 4px;
}

#hero-img-subtext {
  margin-top: 8px;
  font-size: 14px;
  font-weight: 400;
  color: #929292;
  font-size: 12px;
  line-height: 150%;
}




/* Main */ 

#toggle-button {
  transform: scale(0.6);
  transform-origin: top left;
}

.link {
  position: relative;
  color: var(--link-color);
  text-decoration: none;
}

.link::before {
  content: '';
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 1px;
  background-color: var(--link-color);
}

.link:hover::before {
  opacity: 0;
}

.link::after {
  content: '';
  position: absolute;
  left: 0;
  bottom: 0;
  width: 0;
  height: 1px;
  background-color: var(--link-color);
  transition: width 0.7s ease;
}  

.link:hover::after {
  width: 100%;
}  

.works-list {
  margin-top: 36px;
}

.works-list-row {
  display: flex;
  justify-content: space-between;
  margin-bottom: 108px;
  gap: 16px;
}

.work-item {
  width: 25%;
  cursor: pointer;
  transition: opacity 0.3s ease;
}

.work-item-big {
  width: 50%;
}

.work-img {
  width: 100%; 
  position: relative;
  background-color: #929292;
  position: relative;
  overflow: hidden;
  border-radius: 4px;   
}

.work-img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  position: absolute;
  top: 0;
  left: 0; 
  transition: transform 0.5s ease;
}

.work-img img:hover {
  transform: scale(1.05);
}

.work-img::before {
  content: "";
  display: block;
  padding-top: 100%; /* Aspect ratio 1:1 */
}

/*.work-title {
  font-size: 16px;
  margin-top: -8px;
  font-weight: 500;
  line-height: 140%;
}

.work-subtitle {
  font-size: 13px;
  color: #929292;
}*/

.work-title {
  font-size: 16px;
  margin-top: -12px;
  font-weight: 300;
  line-height: 140%;
}

.work-subtitle {
  font-size: 14px;
  font-weight: 600;
}

.work-tag {
  display: inline-block;
  font-size: 12px;
  letter-spacing: -0.2px;
  border: #929292 solid 1px;
  border-radius: 100px;
  padding-top: 4px;
  padding-bottom: 4px;
  padding-left: 8px;
  padding-right: 8px;
}

#click-here-link {
  text-decoration: underline; 
  text-underline-offset: 10px;
  margin-right: 2px;
  color: var(--text-color);
}

#click-here-link:visited {
  text-decoration: none;
}

#top-blank-space1, #top-blank-space2 {
  background-color: var(--background-color);
}

#under-blank-space {
  background-color: var(--background-color);
  width: 100%; 
  padding-bottom: 100%;
}


.toggle-wrapper {
  width: 25%; 
  height: 0;
  margin-top: -14px;
}

.toggle-text {
  padding-top: 24px;
  padding-right: 12px;
  font-size: 14px;
  font-weight: 300;
  line-height: 140%;
}


/* Responsive design */
@media (min-width: 1440px) {
  .main {
    margin-left: calc((100% - 1440px) / 2 + 32px);
    margin-right: calc((100% - 1440px) / 2 + 32px);
  }
}
@media (max-width: 768px) {
  .my-name {
    font-size: 64px;
  }
  .my-description {
    font-size: 24px;
  }
  .works-list-row {
      flex-direction: column;
  }
  .work-item {
      width: 100%;
      margin-bottom: 32px;
  }
  .work-item-big {
    width: 100%;
    margin-bottom: 32px;
  }
  #top-blank-space1, #top-blank-space2 {
    display: none;
  }
  #under-blank-space {
    display: none;
  }
  #work-item-About {
    display: none;
  }
  .works-list-row {
    margin-bottom: 0px;
  }
}
@media (max-width: 600px) {
  body, html {
    width: 100% ;
    overflow-x: hidden;
  }
  .main {
    margin-left: 24px;
    margin-right: 24px;
  }
  .hero {
    flex-direction: column;
  }
  .hero-section1 {
    display: none;
  }
  #divider1 {
    display: none;
  }
  .hero-section2-left-column {
    display: none;
  }
  .hero-section2-right-column {
    padding-left: 0px;
    width: 100%;
  }
  .my-description {
    line-height: 125%;
  }
  .hero-section2 {
    margin-bottom: 36px;
  }
  .toggle-wrapper {
    display: none;
  }
}