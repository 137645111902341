.function-screen {
    display: flex;
    align-items: flex-start;
    width: 100%;
}

.sticky-section {
    position: sticky;
    top: 0;
    left: 0;
    width: 50%;
    height: 100vh;
    padding: 20px;
    font-size: 18px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

#app-screen img {
    width: 240px; /* Adjust to fit the sticky section */
    height: auto; /* Maintain aspect ratio */
    transition: opacity 0.3s ease; /* Smooth transition */
}

.scroll-content {
    width: 50%; /* Takes the rest of the width */
    padding: 20px;
    height: 545vh;
}


.scroll-content section {
    width: 80%;
    height: calc(100vh - 80px); /* Each section takes the full viewport height */
    margin-bottom: 0; /* Remove margin between sections */
    display: flex;
    flex-direction: column;
    justify-content: center; /* Center content horizontally */
}

.divider {
    width: 100%;
    height: 1px;
    background-color: #929292;
    opacity: 0.5;
}


#test-text {
    font-size: 28px;
    font-weight: 600;
    line-height: 140%;
    color: #929292;
}

.white-text {
    color: white;
}