.body {
    display: flex;
    justify-content: space-between;
    margin-left: 32px;
    margin-right: 32px;
}

.left-column {
    width: 16.6%;
    height: calc(100vh - 80px);
    display: flex;
    align-items: center;
    position: fixed;
    top: 80px;
    left: 32px;
}

.shortcut-menu {
    display: flex;
    list-style-type: none;
    flex-direction: column;
    align-items: baseline;
    justify-content: flex-start;
    height: 50vh;
    margin: 0;
    padding: 0;
    transition: opacity 0.4s ease-in-out, transform 0.4s ease-in-out;
}

.shortcut-menu.show {
    opacity: 1;
    transform: translateY(-108px);
}

.shortcut-menu.hide {
    opacity: 0;
    pointer-events: none; 
}

.left-column-list-text {
    margin-bottom: 8px !important;
}

.left-column-list-text a {
    color: #929292;
    text-decoration: none;
}

.left-column-list-text a:hover {
    color: var(--text-color);
}

.left-column-list-text.active {
    font-weight: 400;
    position: relative;
}

.left-column-list-text.active::before {
    content: "•";
    margin-right: 4px;
    color: var(--text-color);
    font-weight: bold;
}
  
.left-column-list-text.active a {
    color: var(--text-color);
}

.theme-toggle {
    position: absolute;
    bottom: 0;
    height: 120px;
    display: flex;
    align-items: center;
    cursor: pointer;
    z-index: 120;
}

.theme-toggle span {
    font-size: 12px;
}

.main-content {
    position: relative;
    width: calc(100% - 16.6% - 16px);
    margin-left: calc(16.6% + 32px);
}

.main-content-hero {
    position: absolute;
    top: 0;
    left: 0;
    width: 100vw;
    height: auto;
    z-index: 10;
}

.hero-img {
    width: 100%;
    height: 960px;
    position: absolute;
    left: 0;
    top: 0;
    overflow: hidden; 
    z-index: 10;
}

.hero-img img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    position: absolute;
    top: 0;
    left: 0; 
}

.project-subtitle {
    position: absolute;
    top: 440px;
    left: 32px;
    right: 32px;
    z-index: 11;
    color: white;
}

.project-title {
    position: absolute;
    top: 440px;
    left: 32px;
    right: 32px;
    font-size: 64px !important;
    font-weight: 400;
    line-height: 140%;
    z-index: 11;
    color: white;
}

.project-details {
    position: absolute;
    top: 960px;
    padding-top: 48px;
    left: 32px;
    right: 32px;
    background-color: var(--background-color);
}

.project-tag-list {
    margin-top: 48px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: 12px;
}

.project-tag-row-1, .project-tag-row-2 {
    width: 50%;
    gap: 12px;
    display: flex;
    justify-content: space-between;
}

.project-tag-row-3 {
    width: 19%;
    display: flex;
    flex-direction: column;
}

.project-tag-item {
    width: 50%;
}

#button-wrapper {
    display: flex;
    flex-direction: column;
}

.button-text {
    padding-top: 8px;
    padding-bottom: 8px;
    padding-left: 12px;
    padding-right: 12px;
    border: 1px solid #929292;
    background-color: #171717;
    border-radius: 8px;
    margin-bottom: 8px;
    text-align: left;
}

.button-text:hover {
    background-color: #2E2E2E;
    border: 1px solid #2E2E2E;
}


/* Body */

.content-section {
    padding-top: 60px;
    padding-bottom: 60px;
    margin-top: 60px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
}

.content-tag {
    font-size: 14px !important;
    color: #848484;
    margin-bottom: 16px !important;
}

.section-subtitle {
    margin-top: 0px !important;
}

.body-text {
    color: var(--light-text-color);
}

.accent-text {
    color: var(--text-color);
}

#overview {
    margin-top: 1260px;
    width: 60%;
}

#my-role, #impact {
    margin-top: 80px;
}

.metrics-box-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: stretch;
    width: 100%;
    gap: 12px;
    margin-top: 12px;
}

.metrics-box-item {
    width: 27%;
    height: auto;
    display: flex;
    flex-direction: column;
    justify-content: baseline;
    align-items: flex-start;
    gap: 0px;
    line-height: 160%;
    font-size: 15px;
    font-weight: 200;
}

.box-accent-text {
    font-size: 24px;
    font-weight: 600;
    line-height: 150%;
    margin-bottom: 8px;
}

.box-text {
    margin-top: 0px;
    color: var(--light-text-color);
}

#metrics-box-text {
    margin-top: 24px;
}

.function-mobile {
    display: none;
    margin-top: 80px;
}

.function-mobile-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 60px;
}

.function-mobile-img {
    width: 200px;
}

#function-mobile-title {
    margin-top: 40px;
    margin-bottom: 16px;
}

#function-mobile-subtext {
    margin-top: 8px;
}

.challenge-content1 {
    display: flex;
    justify-content: space-between;
    gap: 12px;
}

.challenge-content1-text {
    width: 60%;
}

.challenge-img {
    width: 40%;
    height: auto;
    object-fit: contain;
}

.challenge-content2 {
    display: flex;
    gap: 24px;
    margin-top: 40px;
    margin-bottom: 40px;
}

#challenge-item {
    width: 33%;
}

#challenge-text {
    color: var(--text-color);
}

.solution1-problem {
    display: flex;
    justify-content: space-between;
    gap: 24px;
    margin-bottom: 48px;
}

#solution1-problem-left, #solution1-problem-right {
    width: 50%;
}

.solution1 {
    display: flex;
    justify-content: space-between;
    gap: 12px;
    margin-bottom: 180px;
}

.solution-before, .solution-after {
    width: 50%;
}

.solution1-img {
    width: 100%;
    border-radius: 8px;
    margin-bottom: 12px;
}

.solution-point {
    display: flex;
    width: 100%;
    background-color: #232323;
    justify-content: space-between;
    gap: 8px;
    border-radius: 8px;
    padding-top: 6px;
    padding-bottom: 6px;
    padding-right: 12px;
    padding-left: 16px;
    margin-bottom: 12px;
    box-sizing: border-box;
}

.solution-point-left {
    width: 40px;
}

.solution-point-right {
    width: 100%;
}

.solution-number {
    width: 24px;
    height: 24px;
    border-radius: 1000px;
    background-color: #FA0102;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    margin-top: 12px;
    margin-left: 4px;
    margin-right: 4px;
    font-weight: 500;
}

#solution-number-after {
    background-color: #3DD78A;
}

.solution-point-title {
    font-size: 16px;
    font-weight: 500;
    margin-bottom: -4px;
    margin-top: 14px;
}

.solution2-problem {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    gap: 24px;
    margin-bottom: 80px;
    margin-top: 80px;
}

.solution2-problem .italic-text {
    margin-top: 4px;
    margin-bottom: 0px;
}

#solution2-problem-left {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
}

#solution2-problem-left, #solution2-problem-right {
    width: 50%;
}

.solution2-solution {
    display: flex;
    justify-content: space-between;
    gap: 24px;
    margin-bottom: 8px;
}

#solution2-solution-left, #solution2-solution-right {
    width: 50%;
}

#solution2-video {
    width: 100%;
}

.solution2-impact {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    gap: 24px;
    margin-bottom: 180px;
    margin-top: 80px;
}

.solution2-impact .italic-text {
    margin-top: 4px;
    margin-bottom: 0px;
}

.solution2-impact-box-wrapper {
    display: flex;
    justify-content: flex-start;
    width: 100%;
    gap: 24px;
    margin-top: -24px;
}

.solution2-impact-box-item {
    width: 33%;
    height: auto;
    display: flex;
    flex-direction: column;
    justify-content: baseline;
    align-items: flex-start;
    gap: 0px;
    line-height: 160%;
    font-size: 15px;
    font-weight: 200;
}

#solution2-impact-right {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    width: 50%;
}

#solution2-impact-left {
    width: 50%;
}

.solution3-img {
    width: 100%;
}

.solution3-impact {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    gap: 24px;
    margin-top: 80px;
    margin-bottom: 40px;
}

.solution3-impact .italic-text {
    margin-top: 4px;
    margin-bottom: 0px;
}

#solution3-impact-right {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
}

#solution3-impact-left, #solution3-impact-right {
    width: 50%;
}

.funding1, .funding2 {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    gap: 24px;
    margin-bottom: 80px;
}

#funding1-left, #funding1-right, #funding2-left, #funding2-right {
    width: 50%;
}

.marketing1 {
    display: flex;
    justify-content: space-between;
    gap: 24px;
    margin-bottom: 8px;
    margin-top: 40px;
}

.marketing2 {
    display: flex;
    justify-content: space-between;
    gap: 24px;
    margin-bottom: 8px;
    margin-top: 80px;
}

#markting1-left, #marketing1-right, #markting2-left, #marketing2-right {
    width: 50%;
}

#marketing1-right, #marketing2-right {
    margin-top: 4px;
}

.marketing-img {
    width: 100%;
    margin-top: 12px;
}

.branding-img {
    width: 100%;
}

#logo-image-subtext {
    margin-top: 40px;
}

.reflection1, .reflection2 {
    display: flex;
    justify-content: space-between;
    gap: 24px;
    margin-bottom: 8px;
    margin-top: 24px;
}

#reflection1-left, #reflection1-right, #reflection2-left, #reflection2-right {
    width: 50%;
}

#reflection1-right, #reflection2-right {
    margin-top: 4px;
}

#reflection {
    margin-bottom: 100px;
}


/* Footer */

.project-footer {
    z-index: 100;
    margin-top: 120px;
    padding-left: 32px;
    padding-right: 32px;
    z-index: 20;
    position: relative;
    background-color: var(--background-color);
}

.project-footer-top {
    display: flex;
    justify-content: space-between;
    gap: 40px;
}

.project-footer-previous {
    display: flex;
    width: 420px;
    padding: 24px 0px;
    flex-direction: column;
    align-items: flex-start;
}

.project-footer-next {
    display: flex;
    width: 420px;
    padding: 24px 0px;
    flex-direction: column;
    align-items: flex-end;
}

.project-footer-subtitle {
    font-size: 14px;
    color: #929292;
    margin-bottom: 8px;
    overflow: hidden;
    white-space: nowrap; 
    text-overflow: ellipsis; 
}

.project-footer-title {
    font-size: 24px;
    line-height: 140%;
    margin-top: 0;
    margin-bottom: 28px;
    height: 72px;
    overflow: hidden;
}

.project-footer-next .project-footer-title {
    text-align: right;
}

.read-more-button {
    padding-top: 8px;
    padding-bottom: 8px;
    padding-left: 12px;
    padding-right: 12px;
    font-size: 14px;
    color: #929292;
    border: 1px solid #929292;
    background-color: var(--background-color);
    border-radius: 8px;
    margin-bottom: 8px;
    text-align: left;
}

.read-more-button:hover {
    background-color: var(--hover-color);
    border: 1px solid var(--background-color);
}

.divider {
    width: 100%;
    height: 1px;
    background-color: #929292;
    opacity: 0.5;
}



/* Responsive design */
@media (min-width: 1440px) {
    .body {
        margin-left: calc((100% - 1440px) / 2 + 32px) !important;
        margin-right: calc((100% - 1440px) / 2 + 32px) !important;
    }
    .left-column {
        left: calc((100% - 1440px) / 2 + 32px) !important;
    }
    .project-footer {
        padding-left: calc((100% - 1440px) / 2 + 32px) !important;
        padding-right: calc((100% - 1440px) / 2 + 32px) !important;
    }
}
@media (max-width: 768px) {
    .left-column {
        display: none;
    }
    .main-content {
        width: 100%;
        margin-left: 0px;
    }
    .project-tag-list {
        flex-direction: column !important;
    }
    .project-tag-row-1, .project-tag-row-2 {
        width: 100%;
    }
    .project-tag-row-3 {
        width: 100%;
        flex-direction: row !important;
        margin-top: 20px;
    }
    .button-text {
        margin-right: 12px;
    }
    #overview {
        margin-top: 1500px;
    }
    .project-footer-top {
        flex-direction: column;
    }
    .project-footer-previous, .project-footer-next {
        width: 100%;
    }
    .function-wrapper {
        display: none;
    }
    .function-mobile {
        display: block;
    }
    .challenge-content1 {
        flex-direction: column;
    }
    .challenge-content1-text {
        width: 100%;
    }
    .challenge-img {
        width: 100%;
        height: auto;
        object-fit: contain;
    }
    .solution1 {
        flex-direction: column;
    }
    .solution-before, .solution-after {
        width: 100%;
    }
    .solution-before {
        margin-bottom: 24px;
    }
}
@media (max-width: 600px) {
    .body {
        margin-left: 24px;
        margin-right: 24px;
    }
    .project-subtitle {
        left: 24px;
        right: 24px;
    }
    .project-title {
        left: 24px;
        right: 24px;
        font-size: 48px !important;
    }
    .project-details {
        left: 24px;
        right: 24px;
    }
    #overview {
        margin-top: 1600px;
        width: 100%;
    }
    .project-footer {
        padding-left: 24px;
        padding-right: 24px;
    }
    .metrics-box-wrapper {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: stretch;
        width: 100%;
        gap: 12px;
    }
    .metrics-box-item {
        width: 100%;
        justify-content: center;
        align-items: center;
    }
    .box-text {
        text-align: center;
    }
    .challenge-content2, .solution1-problem, .solution2-impact, .solution2-problem, .solution2-solution, .solution3-impact, .funding1, .funding2, .marketing1, .marketing2, .reflection1, .reflection2 {
        flex-direction: column;
    }
    #challenge-item, #solution1-problem-left, #solution1-problem-right, #solution2-impact-left, #solution2-impact-right, #solution2-problem-left, #solution2-problem-right, #solution2-solution-left, #solution2-solution-right, #solution3-impact-left, #solution3-impact-right, #funding1-left, #funding1-right, #funding2-left, #funding2-right, #marketing1-left, #marketing1-right, #marketing2-left, #marketing2-right, #reflection1-left, #reflection1-right, #reflection2-left, #reflection2-right {
        width: 100%;
    }
    #funding2-right, #solution3-impact-right, #solution2-impact-right {
        order: 1;
    }
    #funding2-left, #solution3-impact-left, #solution2-impact-left {
        order: 2;
    }
    #solution2-solution-left, #solution1-problem-left {
        margin-bottom: 0px;
    }
    #solution2-solution-right, #solution1-problem-right {
        margin-top: 0px;
    }
}