.result-box {
    display: flex;
    gap: 48px;
}

.result-box-left-section {
    width: 50%;
}

.description {
    margin-top: 32px;
}

#result-tag {
    margin-bottom: 12px;
}

#result-italic-text {
    font-style: italic;
}

.result-body-text {
    font-size: 14px;
    font-weight: 400;
    line-height: 160%;
    margin-top: 16px;
}

.result-box-right-section {
    width: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.result-img-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
}

.result-box-right-section img {
    max-width: 100%;
    height: auto;
}
  
.result-arrow-button {
    background: none;
    height: 100%;
    border: none;
    font-size: 24px;
    font-weight: 100;
    cursor: pointer;
    padding: 0 1rem;
    color: var(--text-color);
}
  
.dots-container {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 1rem;
}
  
.dot {
    height: 8px;
    width: 8px;
    margin: 0 5px;
    background-color: #929292;
    border-radius: 50%;
    display: inline-block;
    cursor: pointer;
}
  
.dot.active {
    background-color: #ff5f5f; /* Highlight color for the active dot */
}




/* Responsive design */
@media (max-width: 600px) {
    .result-box {
        flex-direction: column;
        align-items: center;
    }
    .result-box-left-section {
        width: 100%;
    }
    .result-box-right-section {
        width: 80%;
    }
}