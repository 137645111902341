:root {
  --background-color: #171717;
  --text-color: white;
  --link-color: white;
  --hover-color: #2E2E2E;
}

[data-theme="dark"] {
  --background-color: #171717;
  --text-color: white;
  --light-text-color: #929292;
  --text-color-rgb: 255, 255, 255;
  --link-color: white;
  --hover-color: #2E2E2E;
}

[data-theme="light"] {
  --background-color: #ffffff;
  --text-color: #1D1D1D;
  --light-text-color: #929292;
  --text-color-rgb: 0, 0, 0;
  --link-color: #1D1D1D;
  --hover-color: #E5E5E5;
}


body {
  background-color: var(--background-color);
  color: var(--text-color);
  margin: 0;
  padding: 0;
}

.App {
  background-color: var(--background-color);
  color: var(--text-color);
}

.main {
  margin-top: 100px;
}

.body {
  margin-top: 100px;
}



/* Font Styles */

.tag-name {
  font-size: 12px;
  color: #929292;
}

.left-column-list-text {
  font-size: 12px;
  font-weight: 300;
  margin-left: 0;
  margin-bottom: 24px;
  color: #929292;
}

.project-title {
  font-family: 'Helvetica', sans-serif;
  font-size: 24px;
}

.project-subtitle {
  font-family: 'Helvetica', sans-serif;
  font-size: 14px;
  font-weight: 300;
  margin-bottom: -4px;
}

.content-tag {
  font-family: 'Helvetica', sans-serif;
  font-size: 12px;
  color: #929292;
  margin-bottom: 24px;
}

.section-subtitle {
  font-family: 'Helvetica', sans-serif;
  font-size: 24px;
  line-height: 140%;
  font-weight: 500;
  margin-top: 36px;
}

.project-description {
  font-family: 'Helvetica', sans-serif;
  line-height: 160%;
  font-size: 16px;
  margin-top: -2px;
}

.tag-description {
  font-family: 'Helvetica', sans-serif;
  font-size: 14px;
  line-height: 150%;
}

.italic-text {
  font-family: 'Helvetica', sans-serif;
  font-size: 18px;
  line-height: 140%;
  font-weight: 400;
}

.body-text {
  font-family: 'Helvetica', sans-serif;
  font-size: 15px;
  font-weight: 400;
  line-height: 160%;
}

.box-title {
  font-family: 'Helvetica', sans-serif;
  font-weight: 600;
  line-height: 180%;
  font-size: 13px;
}

.box-text {
  font-family: 'Helvetica', sans-serif;
  line-height: 160%;
  font-weight: 400;
  font-size: 14px;
}

.image-subtext {
  font-family: 'Helvetica', sans-serif;
  margin-top: 8px;
  margin-bottom: 20px;
  font-size: 14px;
  font-weight: 400;
  color: #929292;
}

.button-text {
  font-family: 'Helvetica', sans-serif;
  font-size: 14px;
  color: #929292;
}