.table {
  font-size: 14px;
  line-height: 140%;
  width: 100%;
  margin-bottom: 120px;
}

.row-1, .row-2, .row-3 {
  display: flex;
  gap: 16px;
  margin-bottom: 24px;
  align-items: flex-start;
}

.column {
  width: 100%;
  margin-top: 16px;
}

.table-element {
  display: flex;
  justify-content: space-between;
  cursor: pointer;
  padding-top: 4px;
  padding-bottom: 4px;
}

.table-arrow {
  font-size: 24px;
  margin-top: 16px;
  margin-left: 20px;
  width: 24px;
  height: 24px;
  text-align: center;
  transition: transform 0.3s ease;
}

.table-arrow.rotated {
  transform: rotate(180deg) translateY(-8px);
}

.table-hidden {
  max-height: 0;
  opacity: 0;
  overflow: hidden;
  transition: max-height 0.4s ease, opacity 0.4s ease;
  color: #929292;
}

.table-hidden.visible {
  max-height: 200px; /* Adjust based on content */
  opacity: 1;
}

.table-hidden-text {
  margin-top: 0px;
  padding-bottom: 6px;
  font-size: 15px;
  line-height: 160%;
}