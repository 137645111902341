.switch {
    position: relative;
    display: inline-block;
    width: 72px;
    height: 32px;
}
  
.switch input {
    opacity: 0;
    width: 0;
    height: 0;
}
  
.slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border: 1.6px solid var(--text-color);;
    transition: .4s;
    border-radius: 34px;
    background-color: var(--background-color);
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 10px;
    font-size: 12px;
    color: var(--text-color);
    overflow: hidden;
}

.slider.dark {
    background-color: var(--background-color); 
}
  
.slider:before {
    position: absolute;
    content: "";
    height: 22px;
    width: 22px;
    left: 4px;
    bottom: 4px;
    background-color: var(--text-color);
    transition: .4s;
    border-radius: 50%;
    z-index: 1; /* Ensure the circle is above the text */
}
  
input:checked + .slider {
    background-color: var(--background-color);
    border: 1.6px solid var(--text-color);
}
  
input:checked + .slider:before {
    transform: translateX(39px);
}
  

/* Light mode styles */
[data-theme="light"] .slider:before {
    background-color: var(--text-color);
}

[data-theme="light"] input:checked + .slider {
    background-color: var(--background-color);
    border: 1.6px solid var(--text-color);
}