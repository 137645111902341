header {
    padding-left: 32px;
    padding-right: 32px;
    text-align: center;
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 80px;
    font-size: 14px;
    font-family: Helvetica, sans-serif;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    width: 100%;
    z-index: 100; 
    color: var(--text-color);
    box-sizing: border-box;
}
  
nav ul {
    list-style-type: none;
    padding-right: 0;
}
  
nav ul li {
    display: inline;
    margin-left: 32px;
}
  
.header-link {
    position: relative;
    color: var(--text-color);
    text-decoration: none;
}

.header-link::before {
    content: '';
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 0px;
    background-color: var(--link-color);
}

.header-link:hover::before {
    opacity: 0;
}

.header-link::after {
    content: '';
    position: absolute;
    left: 0;
    bottom: 0;
    width: 0;
    height: 1.1px;
    background-color: var(--link-color);
    transition: width 0.7s ease;
}  

.header-link:hover::after {
    width: 100%;
}  

.hamburger-menu {
    height: 40px;
    width: 40px;
    cursor: pointer;
}

#hamburger-menu {
    display: none;
}

.header-theme-toggle {
    display: none;
}
  


/*Responsive Design*/
@media (min-width: 1440px) {
    header {
        padding-left: calc((100% - 1440px) / 2 + 32px);
        padding-right: calc((100% - 1440px) / 2 + 32px);
    }
}
@media (max-width: 600px) {
    body, html {
        width: 100%;
    }
    header {
        padding-left: 24px;
        padding-right: 24px;
    }
    nav ul {
        display: none;
        flex-direction: column;
        background-color: var(--background-color);
        position: absolute;
        top: 80px;
        left: 0;
        right: 0;
        padding: 0;
        margin: 0;
        width: 100%;
        height: 100%;
    }
    nav.open ul {
        display: flex;
        width: 100%;
        height: 100vh;
    }
    nav ul li {
        display: block;
        text-align: right;
        margin-top: 16px;
        padding-right: 24px;
        padding-left: 24px;
    }
    #hamburger-menu {
        display: flex;
        justify-content: flex-end;
        align-items: center;
    }
    .menu-icon {
        font-size: 34px;
        position: relative;
        top: -2px;
    }
    .close-icon {
        font-size: 20px;
        position: relative;
    }
    /*.header-theme-toggle {
        display: block;
        text-align: right;
        padding-right: 24px;
        padding-left: 24px;
        margin-top: 80px;
    }
    .header-theme-toggle span {
        font-size: 12px;
    }*/
}